<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Danh sách thống kê thử thách</h6>
              </template>
              <div class="filter-transaction">
                <b-form @submit.prevent="search()" >
                  <div class="row mb-6">
                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Số điện thoại:</label>
                      <input
                          type="text"
                          class="form-control datatable-input"
                          placeholder="Nhập số điện thoại..."
                          v-model="paramFilter.phone"
                      />
                    </div>
                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Tên thử thách:</label>
                      <input
                          type="text"
                          class="form-control datatable-input"
                          placeholder="Nhập tên thử thách..."
                          v-model="paramFilter.challenge_name"
                      />
                    </div>

                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Nhiệm vụ:</label>
                      <select
                          class="form-control form-select"
                          data-col-index="7"
                          v-model="paramFilter.mission_id"
                      >
                        <option value="">Tất cả</option>
                        <option v-for="(item, index) in missions" :key="index" :value="item.id">{{ item.title }}</option>
                      </select>
                    </div>

                    <div class="col-lg-2 mb-lg-0 mb-6">
                      <label>Trạng thái:</label>
                      <select
                          class="form-control form-select"
                          data-col-index="7"
                          v-model="paramFilter.status"
                      >
                        <option value="">Tất cả</option>
                        <option value=1>pending</option>
                        <option value=2>completed</option>
                        <option value=3>failed</option>
                        <option value=4>canceled</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-6">
                    <div class="col-lg-4 mb-lg-0 mb-6">
                      <label>Thời gian tham gia:</label>
                      <div class="input-daterange input-group" id="kt_datepicker">
                        <input
                            type="date"
                            v-model="paramFilter.date_start_join"
                            class="form-control datatable-input"
                            name="start"

                            placeholder="Từ"
                        />
                        <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="la la-ellipsis-h"></i>
                        </span>
                        </div>
                        <input
                            type="date"
                            v-model="paramFilter.date_end_join"
                            class="form-control datatable-input"
                            name="end"
                            placeholder="Đến"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 mb-lg-0 mb-6">
                      <label>Thời gian hoàn thành:</label>
                      <div class="input-daterange input-group" id="kt_datepicker1">
                        <input
                            type="date"
                            v-model="paramFilter.date_start_finish"
                            class="form-control datatable-input"
                            name="start"
                            placeholder="Từ"
                        />
                        <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="la la-ellipsis-h"></i>
                        </span>
                        </div>
                        <input
                            type="date"
                            v-model="paramFilter.date_end_finish"
                            class="form-control datatable-input"
                            name="end"
                            placeholder="Đến"
                        />
                      </div>
                    </div>
                    <div class="col-lg-2 mb-lg-0 mb-6">
                      <label>.</label><br />
                      <button
                          class="btn btn-primary btn-primary--icon"
                      >
                      <span>
                        <i class="la la-search"></i>
                        <span>Tìm kiếm</span>
                      </span>
                      </button>
                    </div>
                  </div>
                </b-form>

              </div>

              <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="fieldsLog"
              >
                <template #cell(user_info)="data">
                  <span> SĐT : {{ data.item.phone }} </span> <br />
                  <span> User ID: {{ data.item.uuid }} </span><br />
                  <span v-if="data.item.name">
                    họ tên: {{ data.item.name }}
                  </span>
                </template>

                <template #cell(status)="data">
                  <span class="label font-weight-bold label-lg label-inline label-light-info" v-if="data.item.status == 1"> pending </span>
                  <span class="label font-weight-bold label-lg label-inline label-light-success" v-else-if="data.item.status == 2"> completed </span>
                  <span class="label font-weight-bold label-lg label-inline label-light-danger" v-else-if="data.item.status == 3"> failed </span>
                  <span class="label font-weight-bold label-lg label-inline label-light-danger" v-else-if="data.item.status == 4"> canceled </span>
                </template>

                <template #cell(action)="data">
                  <a
                      style="color: #0093ca"
                      href="javascript:void(0)"
                      @click="loadChallengeProcess(data.item.id, data.item)"
                      v-b-modal.my-modal
                  >Chi tiết</a>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :total-rows="paginate.total"
                    :per-page="paginate.per_page"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination>
              </div>

              <b-modal id="my-modal" size="lg" hide-footer ref="myModal" :class="['modal-lg']" title="Kết quả thực hiện thử thách">
                <div class="row">
                  <div class="col-md-8">
                    <div>
                      <span style="margin-right: 20px;">Thời gian diễn ra:</span>
                      <span>{{ challengeProcess.date_start }} - {{ challengeProcess.date_end }}</span>
                    </div>
                    <div>
                      <span style="margin-right: 20px;">KPI time:</span>
                      <span v-if="challengeProcess.is_show_kpi_time == 1"> {{ challengeProcess.kpi_int }}</span>
                    </div>

                    <div>
                      <span style="margin-right: 20px;">Thời gian thất bại:</span>
                      <span v-if="challengeProcess.status == 3 || challengeProcess.status == 4">{{ challengeProcess.date_failed }}</span>
                    </div>
                    <div>
                      <span style="margin-right: 20px;">Số lần từ bỏ:</span>
                      <span>{{challengeProcess.num_cancel_current}}/{{challengeProcess.num_cancel_max?challengeProcess.num_cancel_max:'∞'}}</span>
                    </div>
                    <div>
                      <span style="margin-right: 20px;">Trạng thái:</span>
                      <span class="label font-weight-bold label-lg label-inline label-light-info" v-if="challengeProcess.status == 1"> pending </span>
                      <span class="label font-weight-bold label-lg label-inline label-light-success" v-else-if="challengeProcess.status == 2"> completed </span>
                      <span class="label font-weight-bold label-lg label-inline label-light-danger" v-else-if="challengeProcess.status == 3"> failed </span>
                      <span class="label font-weight-bold label-lg label-inline label-light-danger" v-else-if="challengeProcess.status == 4"> canceled </span>
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div>
                      <span style="margin-right: 20px;">SĐT:</span>
                      <span>{{ challengeProcess.phone }}</span>
                    </div>
                    <div>
                      <span style="margin-right: 20px;">User ID:</span>
                      <span>{{ challengeProcess.uuid }}</span>
                    </div>
                    <div>
                      <span style="margin-right: 20px;">Họ tên:</span>
                      <span>{{ challengeProcess.name }}</span>
                    </div>
                    <div >
                      <span style="margin-right: 20px;">Danh mục:</span>
                      <span v-if="challengeProcess.categories" >{{ challengeProcess.categories.name }}</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="margin-top: 10px;">
                    <span class="label font-weight-bold label-lg label-inline label-light-info" > Thông tin nhiệm vụ </span>
                  </div>
                  <b-table
                      table-class="datatable-table"
                      hover
                      tbody-tr-class="datatable-row"
                      thead-tr-class="datatable-row"
                      thead-class="datatable-head"
                      details-td-class="datatable-cell"
                      :items="challengeProcess.missions"
                      :fields="fieldsChallengeProcess"
                  >
                    <template #cell(desc)="data">
                      {{ limitString60(data.item.desc) }}

                    </template>

                    <template #cell(status)="data">
                      <span class="label font-weight-bold label-lg label-inline label-light-info" v-if="data.item.status == 0"> pending </span>
                      <span class="label font-weight-bold label-lg label-inline label-light-success" v-else-if="data.item.status == 1"> completed </span>
                    </template>
                    <template #cell(mission_process)="data">
                      <span v-if="data.item.code == 'PAYMENT'">Đạt {{ numberFormat(data.item.num) }}đ / {{ numberFormat(data.item.total_num) }}đ</span>
                      <span v-if="data.item.code == 'ATTENDANCE'"> ngày</span>
                    </template>
                  </b-table>
                </div>

                <div class="row">
                  <div class="col-md-12" style="margin-top: 10px;">
                    <span class="label font-weight-bold label-lg label-inline label-light-info" > Thông tin quà tặng </span>
                  </div>
                  <b-table
                      table-class="datatable-table"
                      hover
                      tbody-tr-class="datatable-row"
                      thead-tr-class="datatable-row"
                      thead-class="datatable-head"
                      details-td-class="datatable-cell"
                      :items="item_histories"
                      :fields="fieldsBonusHistories"
                  >
                    <template #cell(type_gift)="data">
                      <span v-if="data.item.type == 1"> {{ data.item.campaign_code }} </span>
                      <span v-else-if="data.item.type == 2"> {{ numberFormat(data.item.amount) }}đ </span>
                      <span v-else-if="data.item.type == 3"> {{ numberFormat(data.item.point) }}điểm </span>
                    </template>
                    <template #cell(type)="data">
                      <span v-if="data.item.type == 1"> Voucher </span>
                      <span v-else-if="data.item.type == 2"> Tiền ví thưởng </span>
                      <span v-else-if="data.item.type == 3"> Điểm loyalty </span>
                    </template>
                    <template #cell(status)="data">
                      <span class="label font-weight-bold label-lg label-inline label-light-success" v-if="data.item.status == 1"> Success </span>
                      <span class="label font-weight-bold label-lg label-inline label-light-danger" v-else-if="data.item.status == 2"> Failed </span>
                      <span class="label font-weight-bold label-lg label-inline label-light-danger" v-else> N/A </span>
                    </template>
                    <template #cell(action)="data">
                      <a
                          v-if="data.item.status == 2"
                          style="color: #0093ca"
                          href="javascript:void(0)"
                          @click="retry(data.item.id, data.item)"
                          v-b-modal.my-modal
                      >Bù quà</a>

                    </template>
                  </b-table>
                </div>

              </b-modal>

            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const WalletCampaignRepository = RepositoryFactory.get("Campaign");

export default {
  mixins: [Common],
  data() {
    return {
      paramFilter: {
        phone: "",
        challenge_name: "",
        mission_id: "",
        status: "",
        date_start_join: '',
        date_end_join: '',
        date_start_finish: '',
        date_end_finish: '',
        limit: 20,
      },
      fieldsLog: [
        // {
        //   key: "id",
        //   label: "ID",
        // },
        {
          key: "user_info",
          label: "Users",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "challenge_title",
          label: "Tên thử thách",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "date_join",
          label: "Thời gian tham gia",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "date_finish",
          label: "Thời gian hoàn thành",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status",
          label: "Trạng thái",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "hành động",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      fieldsChallengeProcess: [
        {
          key: "title",
          label: "Nhiệm vụ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "desc",
          label: "Mô tả nhiệm vụ",
          tdClass: "datatable-cell set-width",
          thClass: "datatable-cell",
        },
        {
          key: "mission_process",
          label: "Tiến trình cuối của nhiệm vụ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status",
          label: "Trạng thái",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      fieldsBonusHistories: [
        {
          key: "type_gift",
          label: "Quà tặng",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "type",
          label: "Loại quà tặng",
          tdClass: "datatable-cell set-width",
          thClass: "datatable-cell",
        },
        {
          key: "status",
          label: "Trạng thái nhận quà",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Hành động",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      missions: [],
      items: [],
      challengeProcess: {},
      currentPage: 1,
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      item_missions: [],
      item_histories: []
    };
  },
  created() {
    this.loadMissions();
    this.loadData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thống kê" },
      { title: "Thống kê thử thách", route: "/campaign/report/challenges" },
    ]);
  },
  watch: {
    currentPage() {
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      try {
        this.$bus.$emit("show-loading", true);
        this.paramFilter.page = this.currentPage;
        let response = await WalletCampaignRepository.reportChallenges(this.paramFilter);
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        this.items = body.items;
        this.paginate = body.meta;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.$router.push({path: this.$route.fullPath, query: {page: this.currentPage} });
      this.loadData();
    },
    async loadMissions() {
      try {
        let response = await WalletCampaignRepository.getList();
        this.missions = response.data.data.data;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async loadChallengeProcess (id, item) {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await WalletCampaignRepository.reportChallengeProcess(id);
        this.$bus.$emit("show-loading", false);
        this.challengeProcess = response.data.data.challenge_detail;

        this.item_histories = response.data.data.bonus_histories;
        if (this.item_histories.length == 0) {
          this.item_histories = response.data.data.gift_items;
        }
        this.challengeProcess.uuid = item.uuid;
        this.challengeProcess.phone = item.phone;
        this.challengeProcess.name = item.name;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async retry (id, item) {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await WalletCampaignRepository.retryBonus(id);
        this.$bus.$emit("show-loading", false);
        alert(response.data.message)
        if (response.data.error_code === 0) {
          item.status = 1;
        }
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    }
  },
};
</script>

<style >
.set-width {
  width: 30% !important;
}
select.form-control { -webkit-appearance: menulist; }
</style>
